

const ProjectCard = ({props}) => {
    return (
        <>
        <div className="d-flex gap-3 project-card px-3 pt-3 pb-3 flex-wrap justify-content-center">
            <img src={props.mainImage} alt="" style={{width:"200px"}} className="border-radius-20"></img>
            <div>
                <h6 className="text-infos text-uppercase">{props.title}</h6>
                <p className="light-grey">{props.description}</p>
                {
                    props.disabled ? 
                    <button className="project-btn" disabled>View Live</button>
                    : <button className="project-btn"><a href={props.link} target="_blank">View Live</a></button>
                }
            </div>
        </div>
        </>
    )
}

export default ProjectCard;